<template>
  <div>
    <div class="newgrid">
      <div class="grid-block grid-block-5col demos">
        <div class="demos-header">
          <h1 class="demos-header__title">
            <!-- <span class="demos-header__title-prefix">
              <span></span>
            </span> -->
            <span class="demos-header__title-text">
              <span>Using AI to shape the future of People Development</span>
            </span>
          </h1>
        </div>
        <div class="demos-content">
          <div class="demos-content__wrapper demos-content__standfirst">
            <p>
              Over the past year or so, we’ve explored how AI can create real value by focusing on
              practical applications that deliver impact. No-one wants more slop. Everyone’s already
              got too much to read.
            </p>

            <p>
              This work has pushed us to ask ourselves: What is the UI of AI? We’ve tried to tackle
              this by creating a series of prototypes showcasing AI’s power and potential in People
              Development.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- demo items -->
    <div
      v-for="{ id, title, copy, videoSrc, videoPoster, captionTitle, captionBody } in demoBlocks"
      :key="id"
    >
      <div class="newgrid newgrid-no-row-gap">
        <div class="grid-block grid-block-5col demos">
          <div class="demos-content">
            <div class="demos-content__wrapper">
              <h2 class="demos-content__title">{{ title }}</h2>
            </div>
          </div>
        </div>
      </div>
      <!-- video-->
      <div class="demos-media">
        <div class="with-caption video-with-caption">
          <div class="newgrid newgrid-no-row-gap newgrid-small-col-reverse">
            <div class="demos-media__content">
              <div class="video-with-caption__container">
                <div class="caption-group">
                  <h4 v-if="captionTitle" class="caption-title">
                    {{ captionTitle }}
                  </h4>
                  <p v-if="captionBody" class="caption-body">
                    {{ captionBody }}
                  </p>
                </div>
              </div>
              <div class="video">
                <app-video
                  class="with-caption__media"
                  :poster="require(`@/assets/videos/${videoPoster}`)"
                  :sources="[{ src: require(`@/assets/videos/${videoSrc}`), type: 'mp4' }]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="newgrid">
        <div class="grid-block grid-block-5col demos">
          <div class="demos-content">
            <div class="demos-content__wrapper">
              <div v-html="copy" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / demos -->
    <div class="newgrid">
      <div class="grid-block grid-block-5col demos">
        <div class="demos-content">
          <div class="demos-content__wrapper">
            <h2 class="demos-content__title-lg">We love problems</h2>
            <p>
              Let’s talk about your People Development or other challenges. We specialise in
              designing, building, and testing digital services for large organisations, and we’re
              always looking for new problems to solve. Maybe we can help you too?
              <a :href="`mailto:${email}`">Get in touch</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import AppVideo from "@/components/AppVideo";

export default {
  name: "AIDemos",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      siteTitle: "Tui Media: Independent digital design agency, London.",
      email: "contact@tuimedia.com",
      demoBlocks: [
        {
          id: "leaderCoach",
          title: "LeaderCoach",
          copy:
            "<p>An always-on, context aware, Artificially Intelligent coach that learns about you over time, and talks to you using a natural voice interface.</p><p>Building on the momentum of off-site leadership development retreat, our coach can capture commitment from leaders and work with them to embed and maintain new behaviours through the lens of their day-to-day challenges.</p><p>During each session, the coach AI uses the GROW model to guide users through their challenges. It takes notes too, learning about you and using that context to provide more personalised coaching over time.</p>",
          captionTitle: "A short session with an AI coach",
          videoSrc: "leadercoach-1080.mp4",
          videoPoster: "leadercoach-poster.jpg",
        },
        {
          id: "conversationPractice",
          title: "Conversation Practice",
          copy:
            "<p>We’ve all rehearsed difficult conversations in our heads, hoping to get them right when it matters. But what if AI could take that practice to the next level? This tool provides a safe space to try out conversations with team-mates, bosses, customers or anyone else. Here we’re using AI to provide an experience, not to shovel more content at people.</p>",
          captionTitle: "Practising a difficult conversation",
          videoSrc: "difficult-conversation-1080.mp4",
          videoPoster: "difficult-conversation-poster.jpg",
        },
        {
          id: "myPlan",
          title: "myPlan",
          copy:
            "<p>Preparing an annual development plan is often a time-consuming, frustrating task - it’s especially bad if you’re a team leader, and it all needs to happen at once. Sifting through scattered thoughts, feedback, and aspirations to create something structured and actionable can feel like an unnecessary headache.</p><p>myPlan streamlines the process, turning free-flowing conversations into clear, well-organised development plans. By actively listening and structuring insights in real time, the AI transforms vague ideas into concrete, goal-oriented strategies, making leadership planning faster, easier, and more effective - so leaders can focus on growth, not admin.</p>",
          captionTitle: "Russ demonstrates myPlan's capabilities",
          videoSrc: "myplan-1080.mp4",
          videoPoster: "myplan-poster.jpg",
        },
        {
          id: "assistedJournaling",
          title: "AI assisted journaling",
          copy:
            "<p>An early experiment, seeing how AI can provide users who journal a way of super-charging their self-reflection. The AI does two main things. It generates a set of contextual prompts, specific to the user and the organisation, nudging the user to write, and it provides near-instant feedback, analysis, and guidance, allowing for a dialogue with the user about their entry.</p>",
          captionTitle: "The AI Assisted Journal in action",
          videoSrc: "assisted-journaling-720.mp4",
          videoPoster: "assisted-journaling-poster.jpg",
        },
        {
          id: "coaching",
          title: "Coaching the coaches",
          copy:
            "<p>We see some of our clients trying to provide internal coaching, but the demand almost always far outstrips the supply. There’s a huge need for coaching coaches! We thought we’d have a go at using AI to act as 2 out of the 3 people in a coaching “triad”. Potential coaches can use this to put themselves into a coaching situation with an AI coachee, and get advice and feedback from another AI on how they did.</p>",
          captionTitle: "A demo of how coaching the coaches works",
          videoSrc: "coaching-1080.mp4",
          videoPoster: "coaching-poster.jpg",
        },
      ],
    };
  },
  metaInfo() {
    return {
      title: "Using AI to shape the future of People Development",
    };
  },
  components: {
    AppVideo,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "~@/styles/objects.media-with-caption";

.demos {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 20px;

  &-header {
    margin-top: -80px;
    grid-column: 1/-1;

    @media screen and (min-width: $medium) {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 1fr;
      grid-gap: 20px;
      align-items: flex-end;
      margin-bottom: 68px;
    }

    @media screen and (min-width: $large) {
      margin-bottom: 110px;
    }

    @media screen and (min-width: $extra-large) {
      margin-bottom: 160px;
    }

    &::before {
      content: "";
      display: block;
      width: calc(100% + 32px);
      margin: -16px -16px 0 -16px;
      min-height: 168px;
      aspect-ratio: 959 / 420;
      background: url("~@/assets/images/aidemos/header-bg.webp") 50% 50% / cover;

      @media screen and (min-width: $medium) {
        width: calc(100% + 54px);
        margin-left: -27px;
        margin-right: -27px;
        grid-column: 1 / -1;
        grid-row: 1 / -1;
      }
      @media screen and (min-width: $large) {
        height: 100%;
        // margin-left: -50px;
        // margin-right: -50px;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        grid-column: 3 / -1;
      }
    }

    &__title {
      margin-top: -27px;
      margin-left: -8px;
      margin-bottom: 8px;
      line-height: 1;

      @media screen and (min-width: $medium) {
        grid-column: 2 / -1;
        grid-row: 1 / -1;
        transform: translateY(68px);
      }

      @media screen and (min-width: $large) {
        grid-column: 1 / -1;
        grid-row: 1 / -1;
        margin-bottom: -70px;
        margin-right: 36%;
        transform: translateY(0);
      }

      @media screen and (min-width: $extra-large) {
        margin-bottom: -96px;
        margin-right: 39%;
      }

      &-prefix,
      &-text {
        display: block;
        font-family: "UtopiaStd-BlackHeadline";
      }

      &-prefix span,
      &-text span {
        display: inline-block;
        background-color: $tui-yellow;
      }

      &-prefix {
        font-size: 24px;

        @media screen and (min-width: $large) {
          font-size: 32px;
        }

        @media screen and (min-width: $extra-large) {
          font-size: 40px;
        }
      }

      &-text {
        font-size: 33px;
        line-height: 1.2;
        text-wrap: pretty;

        @media screen and (min-width: $large) {
          padding-right: 0;
          font-size: 40px;
        }

        @media screen and (min-width: $extra-large) {
          font-size: 64px;
          line-height: 1;
        }
      }

      &-prefix span {
        padding: 8px;

        @media screen and (min-width: $large) {
          padding: 8px 8px 0 8px;
          position: relative;
        }

        @media screen and (min-width: $extra-large) {
          padding: 16px 16px 0 16px;
          margin-bottom: -8px;
        }
      }

      &-text span {
        padding: 8px;
        margin-top: -8px;

        @media screen and (min-width: $extra-large) {
          margin-top: -8px;
          padding: 16px;
        }
      }
    }
  }

  &-content {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 20px;

    // @media screen and (min-width: $medium) {
    //   grid-column: 2 / -1;
    // }

    // @media screen and (min-width: $large) {
    //   grid-column: 2 / -2;
    // }

    &__title,
    &__title-lg {
      font-family: "UtopiaStd-BlackHeadline";
      font-size: 21px;
      margin-top: 8px;
      margin-bottom: 16px;
    }

    &__title-lg {
      font-size: 24px;
    }

    // &::v-deep p:not(.demos-content__standfirst),
    // p:not(.demos-content__standfirst) {
    &::v-deep p,
    p {
      font-family: "SlateStd-Regular";
      // font-family: "SlateStd-Light";
      font-size: 18px;
      line-height: 1.5;
      text-wrap: pretty;

      & + & {
        margin-top: 18px;
        margin-bottom: 0;
      }
    }

    &__wrapper {
      grid-column: 1 / -1;

      @media screen and (min-width: $medium) {
        grid-column: 2 / -1;
      }

      @media screen and (min-width: $large) {
        grid-column: 2 / -2;
      }
    }

    &__standfirst p {
      font-family: "SlateStd-Medium";
      font-size: 21px;
      line-height: 1.45833;

      @media screen and (min-width: $large) {
        font-size: 24px;
      }

      & + p {
        margin-top: 24px;
      }
    }

    & a {
      font-family: "SlateStd-Bold";
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

.demos-media {
  &__content {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 20px;
  }

  .video {
    grid-column: 1 / -1;

    @media screen and (min-width: $medium) {
      grid-column: 2 / -1;
    }

    @media screen and (min-width: $large) {
      grid-column: 2 / -2;
    }
  }
}

.caption-group {
  margin-top: 0;
}

.video-with-caption {
  background: #f8d50a;

  .caption-title {
    font-family: "UtopiaStd-Caption";
  }

  // small
  @media screen and (max-width: #{ $medium - 1 }) {
    margin-bottom: 24px;

    &__container {
      margin-bottom: 16px;
      row-gap: 24px;
      grid-column: 1 / -1;
      grid-row: 2;
    }
  }

  // medium
  @media screen and (min-width: $medium) {
    margin-bottom: 32px;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_vm._l((_vm.demoBlocks),function(ref){
var id = ref.id;
var title = ref.title;
var copy = ref.copy;
var videoSrc = ref.videoSrc;
var videoPoster = ref.videoPoster;
var captionTitle = ref.captionTitle;
var captionBody = ref.captionBody;
return _c('div',{key:id},[_c('div',{staticClass:"newgrid newgrid-no-row-gap"},[_c('div',{staticClass:"grid-block grid-block-5col demos"},[_c('div',{staticClass:"demos-content"},[_c('div',{staticClass:"demos-content__wrapper"},[_c('h2',{staticClass:"demos-content__title"},[_vm._v(_vm._s(title))])])])])]),_c('div',{staticClass:"demos-media"},[_c('div',{staticClass:"with-caption video-with-caption"},[_c('div',{staticClass:"newgrid newgrid-no-row-gap newgrid-small-col-reverse"},[_c('div',{staticClass:"demos-media__content"},[_c('div',{staticClass:"video-with-caption__container"},[_c('div',{staticClass:"caption-group"},[(captionTitle)?_c('h4',{staticClass:"caption-title"},[_vm._v(" "+_vm._s(captionTitle)+" ")]):_vm._e(),(captionBody)?_c('p',{staticClass:"caption-body"},[_vm._v(" "+_vm._s(captionBody)+" ")]):_vm._e()])]),_c('div',{staticClass:"video"},[_c('app-video',{staticClass:"with-caption__media",attrs:{"poster":require(("@/assets/videos/" + videoPoster)),"sources":[{ src: require(("@/assets/videos/" + videoSrc)), type: 'mp4' }]}})],1)])])])]),_c('div',{staticClass:"newgrid"},[_c('div',{staticClass:"grid-block grid-block-5col demos"},[_c('div',{staticClass:"demos-content"},[_c('div',{staticClass:"demos-content__wrapper"},[_c('div',{domProps:{"innerHTML":_vm._s(copy)}})])])])])])}),_c('div',{staticClass:"newgrid"},[_c('div',{staticClass:"grid-block grid-block-5col demos"},[_c('div',{staticClass:"demos-content"},[_c('div',{staticClass:"demos-content__wrapper"},[_c('h2',{staticClass:"demos-content__title-lg"},[_vm._v("We love problems")]),_c('p',[_vm._v(" Let’s talk about your People Development or other challenges. We specialise in designing, building, and testing digital services for large organisations, and we’re always looking for new problems to solve. Maybe we can help you too? "),_c('a',{attrs:{"href":("mailto:" + _vm.email)}},[_vm._v("Get in touch")]),_vm._v(". ")])])])])])],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"newgrid"},[_c('div',{staticClass:"grid-block grid-block-5col demos"},[_c('div',{staticClass:"demos-header"},[_c('h1',{staticClass:"demos-header__title"},[_c('span',{staticClass:"demos-header__title-text"},[_c('span',[_vm._v("Using AI to shape the future of People Development")])])])]),_c('div',{staticClass:"demos-content"},[_c('div',{staticClass:"demos-content__wrapper demos-content__standfirst"},[_c('p',[_vm._v(" Over the past year or so, we’ve explored how AI can create real value by focusing on practical applications that deliver impact. No-one wants more slop. Everyone’s already got too much to read. ")]),_c('p',[_vm._v(" This work has pushed us to ask ourselves: What is the UI of AI? We’ve tried to tackle this by creating a series of prototypes showcasing AI’s power and potential in People Development. ")])])])])])}]

export { render, staticRenderFns }